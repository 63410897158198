import React from "react";

const Hero = () => {
  return (
    <div className="container mx-auto">
      <hr className="h-5 my-2 lg:my-10 border-white" />
      <div className="px-10 lg:px-20 py-2 text-center text-white">
        <div className="text-xl">
          <h1> Welcome to</h1>
          <h1 className="text-4xl lg:text-6xl font-bold mb-4 pb-5">
            The See It Send It Blog
          </h1>
        </div>
        <p className="lg:text-xl mb-8">
          Discover insightful articles, the latest updates, and expert opinions
          to inspire and inform your journey.
        </p>
      </div>
      <hr className="h-5 my-2 lg:my-10 border-white" />
    </div>
  );
};

export default Hero;
