import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  ReliabilityAndAuthenticitySVG,
  WorkplaceSafetySVG,
  LackOfDigitalSVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const image1 = require("../../../../assets/images/TraffickingPrevention/phone.jpg");
const image2 = require("../../../../assets/images/TraffickingPrevention/police.jpg");
const image3 = require("../../../../assets/images/TraffickingPrevention/trafficking.jpg");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Our platform makes it easy to report suspicious activities in real-time.",
    "Organization members can use the app to send detailed reports, complete with photos, videos, and GPS locations, directly to your team.",
    "Immediate flow of information enables faster intervention and helps disrupt trafficking activities before they escalate.",
  ];
  const communicationChallenges = [
    "Trafficking prevention often involves coordination between multiple stakeholders.",
    "Our centralized platform allows seamless communication and information sharing between law enforcement, non-profits, and local governments.",
    "By breaking down silos, your team can work more effectively to identify and address trafficking networks",
  ];
  const isolationAndMentalHealth = [
    "Leverage powerful analytics to identify trends and hotspots for trafficking activities.",
    "Our platform aggregates data from reports to provide actionable insights, helping you target resources where they’re needed most.",
    "These insights can guide policy decisions, community education campaigns, and preventative measures.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<WorkplaceSafetySVG />}
            title="Rapid Reporting and Response"
            description={safetyconcerns}
            image={image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<LackOfDigitalSVG />}
            title="Enhanced Collaboration Across Agencies"
            description={communicationChallenges}
            image={image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<ReliabilityAndAuthenticitySVG />}
            title="Data-Driven Insights for Prevention"
            description={isolationAndMentalHealth}
            image={image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
