import React from "react";

import { Fade } from "react-reveal";

import GovernmentAgenciesImage from "../../../../assets/images/GovernmentAgencies/hero.jpg";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const Hero = () => {
  const windowDimensions = useWindowDimension();
  return (
    <Fade bottom>
      <div className="enterprise_hero_container">
        <p className="title-b text-white enterprise_hero_text">
          Government Agencies
        </p>
        <img
          width={windowDimensions.width * 0.92625}
          src={GovernmentAgenciesImage}
          alt="hero_image"
        ></img>
      </div>
    </Fade>
  );
};

export default Hero;
