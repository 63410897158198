import K9 from "../../../assets/images/k9activitytracking/k9activitytracking_image2.png";
import SOS from "../../../assets/images/workplacesafety/workplacesafety_image4.png";
import SafetyMonitoring from "../../../assets/images/home/home_image3.png";
import K9Video from "../../../assets/videos/K9.mp4";
import ElectionSafetyVideo from "../../../assets/videos/ElectionSafety.mp4";
import IncidentReporting from "../../../assets/images/home/home_image6.png";
import PropertyManagement from "../../../assets/videos/PropertyManagement.mp4";
import WorkplaceSafety from "../../../assets/videos/WorkplaceSafety.mp4";
import WorkplaceSafety2 from "../../../assets/images/workplacesafety/workplacesafety_image2.png";

const BlogMedia = {
  K9,
  SOS,
  SafetyMonitoring,
  K9Video,
  ElectionSafetyVideo,
  IncidentReporting,
  PropertyManagement,
  WorkplaceSafety,
  WorkplaceSafety2,
};

export default BlogMedia;
