import React, { useEffect, useState } from "react";

import { Link, matchPath, useLocation } from "react-router-dom";

import {
  PhoneSVG,
  MessageSVG,
  TwitterSVG,
  LocationSVG,
  AppStoreSVG,
  LinkedInSVG,
  FacebookSVG,
  PlayStoreSVG,
  InstagramSVG,
  LogoSVG,
} from "../../assets/svgs";

import "./styles.css";

const routes = [
  "/",
  "/company",
  "/company/", // Added this so that footer links to sections on company page still show footer
  "/lone-workers",
  "/property-management",
  "/k9-activity-tracking",
  "/workplace-safety",
  "/election-safety",
  "/privacy-policy",
  "/terms-of-use",
  "/blog",
  "/trafficking-prevention",
  "/government-agencies",
];

const Footer = (props) => {
  const location = useLocation();

  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    // Fix this logic at some point
    if (
      !routes.includes(location.pathname) &&
      matchPath("/blog/*", location.pathname) === null
    ) {
      setIsHide(true);
    } else {
      setIsHide(false);
    }
  }, [location]);

  const Linker = (linkerProps) => {
    return (
      <a href={linkerProps.link} className={linkerProps.styles}>
        {linkerProps.children}
      </a>
    );
  };

  const SocialLinker = ({ styles }) => {
    return (
      <div className={`social ${styles}`}>
        <Linker
          link="https://www.instagram.com/seeitsendit"
          styles="socialLink"
        >
          <InstagramSVG width={24} height={24} />
        </Linker>
        <Linker
          link="https://www.facebook.com/people/See-It-Send-It/61551422972020/"
          styles="socialLink"
        >
          <FacebookSVG width={24} height={24} />
        </Linker>
        <Linker link="https://x.com/seeitsendit" styles="socialLink">
          <TwitterSVG width={24} height={24} />
        </Linker>
        <Linker
          link="https://www.linkedin.com/company/cloudscann"
          styles="socialLink"
        >
          <LinkedInSVG width={24} height={24} />
        </Linker>
      </div>
    );
  };

  return (
    <>
      {isHide ? null : (
        <footer className="footer">
          <div className="top">
            <div className="item displayLogo">
              <div className="title">
                <Link to="/" className="logo">
                  <LogoSVG width={120} />
                </Link>
              </div>
              <div className="contentItem">
                <PhoneSVG width={24} height={24} className="icon" />
                <span className="h4-r text-white">+1(516)307-8777</span>
              </div>
              <div className="contentItem">
                <MessageSVG width={24} height={24} className="icon" />
                <span className="h4-r text-white">contact@seeitsendit.com</span>
              </div>
              <div className="contentItem">
                <LocationSVG width={24} height={24} className="icon" />
                <span className="h4-r text-white">
                  226 7th Street Suite 203, Garden City, NY 11530
                </span>
              </div>
            </div>

            <div className="item mobileLogo">
              <div className="title">
                <Link to="/" className="logo">
                  <LogoSVG width={100} />
                </Link>
              </div>
              <div className="contentItem">
                <span className="h4-r text-white">+1(516)307-8777</span>
              </div>
              <div className="contentItem">
                <span className="h4-r text-white" styles="email-address">
                  contact@seeitsendit.com
                </span>
              </div>
            </div>

            <div className="item mobileSocial">
              <div className="title">
                <SocialLinker />
              </div>
              <div className="contentItem">
                <span className="h4-r text-white">
                  226 7th Street Suite 203, Garden City, NY 11530
                </span>
              </div>
            </div>

            <div className="item product">
              <div className="title">
                <span className="h2-b text-white">Product</span>
              </div>
              <div className="contentItem">
                <Linker link="/#helloToVC3">
                  <span className="h4-r text-white hover">
                    Discover See It Send It
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/#discoverFeatures">
                  <span className="h4-r text-white hover">Statistics</span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/request-demo">
                  <span className="h4-r text-white hover">Request Demo</span>
                </Linker>
              </div>
            </div>

            <div className="item company">
              <div className="title">
                <span className="h2-b text-white">Company</span>
              </div>
              <div className="contentItem">
                <Linker link="/company/#our-journey">
                  <span className="h4-r text-white hover">Our Journey</span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/company/#about-us">
                  <span className="h4-r text-white hover">About Us</span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/company/#birth">
                  <span className="h4-r text-white hover">
                    The Birth of See It Send It
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/company/#statistics">
                  <span className="h4-r text-white hover">
                    Company Statistics
                  </span>
                </Linker>
              </div>
            </div>

            <div className="item enterprise">
              <div className="title">
                <span className="h2-b text-white">Industries</span>
              </div>
              <div className="contentItem">
                <Linker link="/lone-workers">
                  <span className="h4-r text-white hover">Lone Workers</span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/property-management">
                  <span className="h4-r text-white hover">
                    Property Management
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/k9-activity-tracking">
                  <span className="h4-r text-white hover">
                    K9 Activity Tracking
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/workplace-safety">
                  <span className="h4-r text-white hover">
                    Workplace Safety
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/election-safety">
                  <span className="h4-r text-white hover">Election Safety</span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/trafficking-prevention">
                  <span className="h4-r text-white hover">
                    Trafficking Prevention
                  </span>
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="/government-agencies">
                  <span className="h4-r text-white hover">
                    Government Agencies
                  </span>
                </Linker>
              </div>
            </div>

            <div className="item contact">
              <div className="other">
                <div className="blog blogItem">
                  <Linker link="/blog">
                    <span className="h2-b text-white hover">Blog</span>
                  </Linker>
                </div>
                <Linker link="/contact">
                  <span className="h2-b text-white hover">Contact Us</span>
                </Linker>
              </div>
            </div>

            <div className="item desktopStore">
              <div className="contentItem">
                <Linker link="https://apps.apple.com/us/app/visualc3/id1527632764">
                  <AppStoreSVG />
                </Linker>
              </div>
              <div className="contentItem">
                <Linker link="https://play.google.com/store/apps/details?id=com.visualc3.copper">
                  <PlayStoreSVG />
                </Linker>
              </div>
            </div>
          </div>

          <div className="item mobileStore">
            <div className="contentItem">
              <Linker link="https://apps.apple.com/us/app/visualc3/id1527632764">
                <AppStoreSVG width={130} />
              </Linker>
            </div>
            <div className="contentItem">
              <Linker link="https://play.google.com/store/apps/details?id=com.visualc3.copper">
                <PlayStoreSVG width={130} />
              </Linker>
            </div>
          </div>

          <div className="bottom">
            <div className="allRight">
              <span className="h5-r text-white">
                © 2025 CloudScann. All rights reserved. |
              </span>
              <a href="/privacy-policy">
                <span className="h5-r text-white hover"> Privacy Policy</span>
              </a>
              <span className="h5-r text-white hover"> | </span>
              <a href="/terms-of-use">
                <span className="h5-r text-white hover">Terms of Use</span>
              </a>
            </div>
            <SocialLinker styles="desktopSocial" />
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
