import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  ReliabilityAndAuthenticitySVG,
  WorkplaceSafetySVG,
  LackOfDigitalSVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const image1 = require("../../../../assets/images/GovernmentAgencies/first.jpg");
const image2 = require("../../../../assets/images/GovernmentAgencies/second.jpg");
const image3 = require("../../../../assets/images/GovernmentAgencies/third.jpg");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Seamless communication across multiple agencies in real-time, eliminating delays caused by multiple dispatch centers.",
    "Direct information delivery from a single source, ensuring clarity and speed.",
  ];
  const communicationChallenges = [
    "One central monitoring dashboard prevents communication lag and allows for a single-pane-of-glass command control system.",
    "Nationwide geofencing enables precise location tagging, maximizing operational efficiency anywhere in the country.",
  ];
  const isolationAndMentalHealth = [
    "Real-time data access supports cost-effective planning, instant course corrections, and live tracking of results.",
    "Streamline report generation to provide accurate information for internal decision-makers, media, and/or public information officers.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<WorkplaceSafetySVG />}
            title="Efficient and Effective Communication"
            description={safetyconcerns}
            image={image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<LackOfDigitalSVG />}
            title="Enhanced Situational Awareness"
            description={communicationChallenges}
            image={image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<ReliabilityAndAuthenticitySVG />}
            title="Real-Time Data for Informed Decision-Making"
            description={isolationAndMentalHealth}
            image={image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
