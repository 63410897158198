import React, { Suspense, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import BlogMedia from "./BlogMedia";

// TODO: Implement better use of Suspense
// TODO: Implement lazy loading for images/video
// TODO: Should add check to make sure BlogMedia[image] exists before rendering image
export const BlogPost = () => {
  const { slug } = useParams();
  const BlogPosts = require("./articles.json");

  const [content, setContent] = useState(null);
  const post = BlogPosts[slug];

  const { date, video, image } = post;

  let markdownContent = process.env.PUBLIC_URL + `/docs/${slug}.md`;

  useEffect(() => {
    if (content === null) {
      fetch(markdownContent)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setContent(text);
        });
    }
  }, [content, markdownContent]);

  if (!post) {
    return (
      <div className="flex flex-col items-center text-white pt-20 pb-40">
        <p className="py-2">We're sorry.</p>
        <p>The blog post you've requested no longer exists.</p>
      </div>
    );
  }

  return (
    <>
      {/* Blog Post */}
      <div className="flex flex-col items-center px-5">
        <div className="px-5">
          {/* Video or Image*/}
          <Suspense fallback={<div>Loading image...</div>}>
            {image ? (
              <img
                width={400}
                height={200}
                src={BlogMedia[image]}
                alt="BlogImage"
              />
            ) : (
              <video width={400} height={200} src={BlogMedia[video]} />
            )}
          </Suspense>
        </div>
        {/* Hero Text */}
        <hr className="py-5" />
        <p className="text-base text-center py-5">Posted: {date}</p>
        {!content ? null : (
          <article className="prose dark dark:prose-invert py-10">
            <ReactMarkdown>{content}</ReactMarkdown>
          </article>
        )}
      </div>
    </>
  );
};
