import React from "react";

import BlogMedia from "./BlogMedia";
import { Link } from "react-router-dom";

// TODO: Implement better use of Suspense
// TODO: Implement lazy loading for images/video/document
// TODO: Should add check to make sure BlogMedia[image] exists before rendering image
export const BlogPosts = () => {
  const posts = require("./articles.json");
  return (
    <ul>
      {Object.entries(posts).map(
        ([slug, { title, subtitle, video, image }]) => (
          <li key={slug}>
            <div className="flex flex-row pb-5 items-center justify-around">
              <div className="px-5 pb-2 flex-1 w-[100px]">
                {/* Video or Image*/}
                {image ? (
                  <img
                    width={400}
                    height={200}
                    src={BlogMedia[image]}
                    alt="BlogImage"
                  />
                ) : (
                  <video width={400} height={200} src={BlogMedia[video]} />
                )}
              </div>
              {/* Hero Text */}
              <div className="px-2 flex-1">
                <Link to={`/blog/${slug}`}>
                  <p className="text-2xl lg:text-4xl underline">{title}</p>
                  <p className="text-base">{subtitle}</p>
                </Link>
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
