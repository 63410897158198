import React from "react";

import { Fade } from "react-reveal";
import Hero from "./components/Hero";
import { Outlet } from "react-router-dom";
import { InTheNews } from "./components/InTheNews";

const Blog = () => {
  // This is done to import tailwind only to this file, so as not to cause issues elsewhere
  require("tailwindcss/tailwind.css");
  return (
    <Fade bottom>
      <div className="twp">
        <div className="container mx-auto flex flex-col items-center">
          {/* Hero Section */}
          <Hero />
          {/* Highlighted Blog Post */}
          <Outlet />
        </div>
        {/* Related Articles & Videos */}
        <InTheNews />
      </div>
    </Fade>
  );
};

export default Blog;
