import React from "react";
import BlogMedia from "./BlogMedia";

export const InTheNews = () => {
  const newsArticles = require("./newsArticles.json");
  return (
    <div className="container mx-auto">
      <hr className="h-5 my-2 lg:my-10 border-white" />
      <p className="text-center text-4xl font-bold pb-10">In The News</p>
      <ul className="flex flex-col lg:flex-row">
        {Object.entries(newsArticles).map(
          ([slug, { url, title, subtitle, image }]) => (
            <li key={slug}>
              <article className="overflow-hidden rounded-lg border shadow-sm border-gray-800 bg-gray-900 shadow-gray-700/25 h-full m-5 lg:m-1">
                <img
                  alt=""
                  src={BlogMedia[image]}
                  className="h-56 w-full object-cover"
                />

                <div className="p-4 sm:p-6">
                  <h3 className="text-lg font-medium text-white">{title}</h3>

                  <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-400">
                    {subtitle}
                  </p>

                  <a
                    href={url}
                    className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
                  >
                    Find out more
                    <span
                      aria-hidden="true"
                      className="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                    >
                      &rarr;
                    </span>
                  </a>
                </div>
              </article>
            </li>
          )
        )}
      </ul>
      <hr className="h-5 my-2 lg:my-10 border-white" />
    </div>
  );
};
